<template>

  <div>
    <div class="container">

      <div class="question-bank-button">
        <button type="button" class="btn btn-primary">Web前端面试题库</button>
        <button type="button" class="btn btn-secondary">PHP面试题库</button>
        <button type="button" class="btn btn-success">Java面试题库</button>
        <button type="button" class="btn btn-danger">Python面试题库</button>
        <!-- <button type="button" class="btn btn-warning">C++面试题库</button>
        <button type="button" class="btn btn-info">Go面试题库</button> -->
      </div>
      

        <!-- <br/><br/><br/><br/><br/><br/><br/><br/><br/>
        <div class="row course-part">
          <div class="video-part-title">最新课程</div>
          <div class="card-deck" >
            <div class="col-xs-12 col-sm-6 col-md-4" v-for="item in list" :key="item.id">
              {{ item.title }}
            </div>
          </div>
        </div> -->

    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-3 question-sidebar-position">
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center">
              基础知识
              <span class="badge badge-primary badge-pill">14</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              面向对象
              <span class="badge badge-primary badge-pill">2</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              框架原理
              <span class="badge badge-primary badge-pill">1</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Laravel
              <span class="badge badge-primary badge-pill">14</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              MySQL
              <span class="badge badge-primary badge-pill">2</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Redis
              <span class="badge badge-primary badge-pill">1</span>
            </li>
          </ul>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3"></div>

        <div class="col-xs-12 col-sm-6 col-md-7">
            <div data-spy="scroll" data-target="#navbar-example3" data-offset="0">
              <h3>
                <br/><br/><b><center style="color:red;">面试达目前在开发中。。</center></b><br/><br/>
              </h3>

              <h5 id="question1"><b>
                服务器最常见的状态码
              </b></h5>
              <p>
                200：服务器成功返回网页 <br/>
                403：（禁止） 服务器拒绝请求 <br/>
                404：请求的网页不存在 <br/>
                503：服务不可用 <br/>

                常考的服务器错误码：5系列<br/>

                这些状态代码表示服务器在尝试处理请求时发生内部错误。 这些错误可能是服务器本身的错误，而不是请求出错。

                代码 说明<br/>
                500：（服务器内部错误） 服务器遇到错误，无法完成请求。<br/>
                501：（尚未实施） 服务器不具备完成请求的功能。 例如，服务器无法识别请求方法时可能会返回此代码。<br/>
                502：（错误网关） 服务器作为网关或代理，从上游服务器收到无效响应。<br/>
                503：（服务不可用） 服务器目前无法使用（由于超载或停机维护）。 通常，这只是暂时状态。<br/>
                504：（网关超时） 服务器作为网关或代理，但是没有及时从上游服务器收到请求。<br/>
                505：（HTTP 版本不受支持） 服务器不支持请求中所用的 HTTP 协议版本。<br/>
              </p>
              <h5 id="question2"><b>
                一个PHP请求的过程（真题）
              </b></h5>
              <p>
                fpm是FastCGI Process Manager的缩写，那么，fpm就是FastCGI进程管理器的简称。php-fpm就是php中的FastCGI进程管理器。<br/>
                php5.3之前的版本，php-fpm是一个第三方的补丁包，php5.3和之后的版本，被集成到php的源码中。<br/>
                php-fpm提供了更好的PHP进程管理方式，可以有效控制内存和进程、可以平滑重载PHP配置。<br/>
                <br/>
                FastCGI 是一个协议规范，需要每个语言具体去实现，PHP-FPM 就是 PHP 版本的 FastCGI 协议实现。<br/>
                PHP-FPM实现PHP脚本与Web服务器（通常是 Nginx）之间的通信，构建起 PHP 解释器与 Web 服务器之间的桥梁。<br/>
                它负责管理一个进程池，处理来自Web服务器的请求。<br/>
                <br/>
                要想php支持php-fpm，只需要在编译php源码的时候带上 --enable-fpm 就可以了<br/>
              </p>
              <h5 id="question3"><b>
                服务器最常见的状态码
              </b></h5>
              <p>
                200：服务器成功返回网页 <br/>
                403：（禁止） 服务器拒绝请求 <br/>
                404：请求的网页不存在 <br/>
                503：服务不可用 <br/>

                常考的服务器错误码：5系列<br/>

                这些状态代码表示服务器在尝试处理请求时发生内部错误。 这些错误可能是服务器本身的错误，而不是请求出错。

                代码 说明<br/>
                500：（服务器内部错误） 服务器遇到错误，无法完成请求。<br/>
                501：（尚未实施） 服务器不具备完成请求的功能。 例如，服务器无法识别请求方法时可能会返回此代码。<br/>
                502：（错误网关） 服务器作为网关或代理，从上游服务器收到无效响应。<br/>
                503：（服务不可用） 服务器目前无法使用（由于超载或停机维护）。 通常，这只是暂时状态。<br/>
                504：（网关超时） 服务器作为网关或代理，但是没有及时从上游服务器收到请求。<br/>
                505：（HTTP 版本不受支持） 服务器不支持请求中所用的 HTTP 协议版本。<br/>
              </p>

              <h5>
                <br/><b><center style="color:red;">免费开放3题，查看后续试题需要题库码<br/>
                题库码内置到面试课程中，每月更新一次</center></b><br/>
              </h5>

              <!-- <center><button type="button" class="btn btn-success">购买课程，获取题库码</button></center><br/><br/> -->

            </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-2 navbar-position">
          <nav id="navbar-part" class="navbar navbar-light bg-light">
            <nav class="nav nav-pills1 flex-column">
              <a class="nav-link" href="#question1">服务器最常见的状态码</a>
              <a class="nav-link" href="#question2">一个PHP请求的过程（真题）</a>
              <a class="nav-link" href="#question3">php中抽象类和接口的区别</a>
              <a class="nav-link" href="#question4">extends和implements区别</a>
              <a class="nav-link" href="#question5">PHP如何读取两个数组其中一列合并</a>
              <a class="nav-link" href="#question6">PHP的运作模式有几种，请对使用场景作简要说明</a>
            </nav>
          </nav>
        </div>

      </div>
    </div>
    

  </div>
</template>

<script>
import axios from 'axios'

export default {
  // name: 'Index',
  props:['user'],
  data() {
    return {
      list: null,
    }
  },
  created() {
    // this.getList()
  },
  methods: {
    async getList() {     
      const response = await axios.post('/api/web/course/newList');
      const items = response.data.data.data
      this.list = items
      // console.log(items[0]['body'])
    },
    async course(id) {
      this.$router.push({path:`/course/${id}`})
    }
  }
}
</script>
